import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")
  const _component_a_select = _resolveComponent("a-select")
  const _component_a_form_item = _resolveComponent("a-form-item")
  const _component_upload = _resolveComponent("upload")
  const _component_a_input = _resolveComponent("a-input")
  const _component_a_form = _resolveComponent("a-form")
  const _component_drawer_box = _resolveComponent("drawer-box")

  return (_openBlock(), _createBlock(_component_drawer_box, {
    title: _ctx.info.Id ? '修改轮播图' : '添加轮播图',
    width: "600px",
    visible: _ctx.show,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_ctx.show = $event)),
    onDrawerSubmit: _ctx.haeldAdd
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        layout: "vertical",
        ref: "formRef",
        model: _ctx.info,
        rules: _ctx.roleInfo
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "平台" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.info.Client,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.info.Client = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.clientEnum, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.code,
                      value: item.code
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "图片",
            name: "Avatar"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_upload, {
                max: 1,
                multiple: false,
                path: "/banner",
                list: _ctx.imgUrlComputed,
                "onUpdate:list": _cache[2] || (_cache[2] = ($event: any) => (_ctx.imgUrlComputed = $event))
              }, null, 8, ["list"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "跳转链接" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.info.LinkUrl,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.info.LinkUrl = $event)),
                placeholder: "跳转链接"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "排序" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.info.Sort,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.info.Sort = $event)),
                placeholder: "排序"
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["title", "visible", "onDrawerSubmit"]))
}