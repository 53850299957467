export const infoModel = {
  Id: 0,
  LivePlatform: 0,
  RoomNumber: '',
  Sort: 0,
};

export const tableModel = [
  { title: '直播平台', dataIndex: 'LivePlatformStr', key: 'LivePlatformStr' },
  { title: '直播间号', key: 'RoomNumber', dataIndex: 'RoomNumber' },
  { title: '排序', dataIndex: 'Sort', key: 'Sort' },
  { title: '操作', key: 'action', slots: { customRender: 'action' } },
];

export const searchModel = {
  RoomNumber: '',
  LivePlatform: 0,
  PageSize: 10,
  PageIndex: 1,
};

export const LiveEnum = [{ code: 0, value: '斗鱼' }];
