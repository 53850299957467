import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, reactive } from 'vue';
import { Radio, Cascader } from 'ant-design-vue';
import { routerAdd, routerList, routerDel } from '@/api/company';
import { message } from 'ant-design-vue';
import { awaitWrap } from '@/util/index';
import { ModalError } from '@/util/message';

export default _defineComponent({
  expose: [],
  setup(__props) {

const ACascader = Cascader;
const RadioGroup = Radio.Group;

const routerColumns = [
  {
    title: '标题',
    dataIndex: 'Title',
    key: 'Title',
  },
  {
    title: '类型',
    dataIndex: 'RouteType',
    key: 'RouteType',
    slots: { customRender: 'RouteType' },
  },
  {
    title: '链接',
    dataIndex: 'RouteUrl',
    key: 'RouteUrl',
  },
  {
    title: '排序',
    dataIndex: 'Sort',
    key: 'Sort',
  },
  {
    title: 'icon',
    dataIndex: 'RouteIcon',
    key: 'RouteIcon',
  },
  { title: '操作', key: 'action', width: '300px', slots: { customRender: 'action' } },
];

const routerOptions = [
  { label: '菜单', value: 0 },
  { label: '页面', value: 1 },
  { label: '接口', value: 2 },
];
const treeRouter = ref([]);

async function getRouterTree() {
  const { code, data } = await routerList();
  if (code === 200) {
    treeRouter.value = data;
    console.log(treeRouter.value);
  }
}

const routerInfo: any = ref({
  Id: 0,
  ParentId: 0,
  RouteIcon: '',
  RouteType: 0,
  RouteUrl: '',
  Sort: 1,
  Title: '',
});

const roleRouterInfo = {
  ParentId: [{ required: true, message: '选择父路由', trigger: 'change' }],
  RouteUrl: [{ required: true, message: '请输入地址', trigger: 'change' }],
  Title: [{ required: true, message: '请输入标题', trigger: 'change' }],
};

const routerDrawer = ref(false);
function handleAddRouter() {
  routerDrawer.value = true;
  routerInfo.value = {
    Id: 0,
    ParentId: [],
    RouteIcon: '',
    RouteType: 0,
    RouteUrl: '',
    Sort: 1,
    Title: '',
  };
}

function handleEditRouter(el: any) {
  routerDrawer.value = true;
  routerInfo.value = {
    Id: el.Id,
    ParentId: [el.ParentId],
    RouteIcon: el.RouteIcon,
    RouteType: el.RouteType,
    RouteUrl: el.RouteUrl,
    Sort: el.Sort,
    Title: el.Title,
  };
}

async function haeldAddRouter(callback: any) {
  const params = JSON.parse(JSON.stringify(routerInfo.value));
  if (params.RouteType === 0) {
    params.RouteUrl = '';
  }

  if (params.RouteType !== 0) {
    params.RouteIcon = '';
  }

  if (params.ParentId.length) {
    params.ParentId = params.ParentId.pop();
  } else {
    params.ParentId = 0;
  }

  const { code, data } = await awaitWrap(routerAdd(params));
  if (code === 200) {
    callback();
    message.success('添加成功');
    getRouterTree();
  } else {
    console.log(data);

    callback();
  }
}

function handleDelRouter(id: any) {
  ModalError('你确定要删除', async () => {
    const { code } = await awaitWrap(routerDel(id));
    return new Promise((resolve, reject) => {
      if (code === 200) {
        message.success('删除成功');
        getRouterTree();
        resolve('');
      } else {
        reject();
      }
    });
  });
}

getRouterTree();

return (_ctx: any,_cache: any) => {
  const _component_search_box = _resolveComponent("search-box")
  const _component_a_divider = _resolveComponent("a-divider")
  const _component_a_table = _resolveComponent("a-table")
  const _component_table_box = _resolveComponent("table-box")
  const _component_a_form_item = _resolveComponent("a-form-item")
  const _component_a_input = _resolveComponent("a-input")
  const _component_a_form = _resolveComponent("a-form")
  const _component_drawer_box = _resolveComponent("drawer-box")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_search_box, { onHaeldConfirm: handleAddRouter }),
    _createVNode(_component_table_box, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          columns: routerColumns,
          rowKey: "Id",
          pagination: false,
          childrenColumnName: "Child",
          "data-source": treeRouter.value
        }, {
          RouteType: _withCtx(({ record }) => [
            _createVNode("span", null, _toDisplayString(['菜单', '页面', '接口'][record.RouteType]), 1)
          ]),
          action: _withCtx(({ record }) => [
            _createVNode("a", {
              href: "javascript:;",
              onClick: ($event: any) => (handleEditRouter(record))
            }, "修改", 8, ["onClick"]),
            _createVNode(_component_a_divider, { type: "vertical" }),
            _createVNode("a", {
              href: "javascript:;",
              onClick: ($event: any) => (handleDelRouter(record.Id))
            }, "删除", 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["data-source"])
      ]),
      _: 1
    }),
    _createVNode(_component_drawer_box, {
      title: "添加路由",
      width: "600px",
      visible: routerDrawer.value,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (routerDrawer.value = $event)),
      onDrawerSubmit: haeldAddRouter
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "vertical",
          model: routerInfo.value,
          rules: roleRouterInfo
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "父级理由" }, {
              default: _withCtx(() => [
                _createVNode(_unref(ACascader), {
                  "change-on-select": "",
                  value: routerInfo.value.ParentId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (routerInfo.value.ParentId = $event)),
                  fieldNames: { label: 'Title', value: 'Id', children: 'Child' },
                  options: treeRouter.value,
                  placeholder: "选择路由"
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "路由类型" }, {
              default: _withCtx(() => [
                _createVNode(_unref(RadioGroup), {
                  bindchange: "",
                  options: routerOptions,
                  value: routerInfo.value.RouteType,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (routerInfo.value.RouteType = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _withDirectives(_createVNode(_component_a_form_item, { label: "路由地址" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: routerInfo.value.RouteUrl,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (routerInfo.value.RouteUrl = $event)),
                  placeholder: "输入路由地址"
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 512), [
              [_vShow, routerInfo.value.RouteType !== 0]
            ]),
            _withDirectives(_createVNode(_component_a_form_item, { label: "路由图标" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: routerInfo.value.RouteIcon,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (routerInfo.value.RouteIcon = $event)),
                  placeholder: "输入路由图标"
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 512), [
              [_vShow, routerInfo.value.RouteType === 0]
            ]),
            _createVNode(_component_a_form_item, { label: "排序" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: routerInfo.value.Sort,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (routerInfo.value.Sort = $event)),
                  placeholder: "输入排序"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "标题" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: routerInfo.value.Title,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (routerInfo.value.Title = $event)),
                  placeholder: "输入标题"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})