import request from './axios';

//添加轮播图
export const bannerAdd = (data: any): Promise<any> => {
  return request({
    url: '/home/banner/add',
    method: 'post',
    data: data,
  });
};

//轮播图详情
export const bannerDetail = (id: any): Promise<any> => {
  return request({
    url: `/home/banner/${id}/detail`,
    method: 'get',
  });
};

//删除轮播图
export const bannerDel = (id: any): Promise<any> => {
  return request({
    url: `/home/banner/${id}/del`,
    method: 'DELETE',
  });
};

//轮播图列表
export const bannerList = (params: any): Promise<any> => {
  return request({
    url: `/home/banner/list`,
    method: 'get',
    params: params,
  });
};

//添加直播间
export const liveAdd = (data: any): Promise<any> => {
  return request({
    url: '/home/live/add',
    method: 'post',
    data: data,
  });
};

//直播间详情
export const liveDetail = (id: any): Promise<any> => {
  return request({
    url: `/home/live/${id}/detail`,
    method: 'get',
  });
};

//删除直播间
export const liveDel = (id: any): Promise<any> => {
  return request({
    url: `/home/live/${id}/del`,
    method: 'DELETE',
  });
};

//直播间列表
export const liveList = (params: any): Promise<any> => {
  return request({
    url: `/home/live/list`,
    method: 'get',
    params: params,
  });
};
