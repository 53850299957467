export const infoModel = {
  Client: 0,
  Id: 0,
  ImgUrl: '',
  Sort: 0,
};

export const tableModel = [
  { title: '类型', dataIndex: 'ClientStr', key: 'ClientStr' },
  { title: '图片', key: 'img', slots: { customRender: 'img' } },
  { title: '创建时间', dataIndex: 'InsertDate', key: 'InsertDate' },
  { title: '排序', dataIndex: 'Sort', key: 'Sort' },
  { title: '操作', key: 'action', slots: { customRender: 'action' } },
];

export const searchModel = {
  Client: '',
  PageSize: 10,
  PageIndex: 1,
};

export const clientEnum = [
  { code: 0, value: 'pc' },
  { code: 1, value: 'h5' },
  { code: 2, value: '桌面' },
];
