
import { defineComponent, ref, reactive } from 'vue';
import { liveDel, liveList } from '@/api/site';
import { tableModel, searchModel, LiveEnum } from '@/data/modules/site/live';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
import { ModalError } from '@/util/message';
import _ from 'lodash';
export default defineComponent({
  emits: ['handle-info'],
  setup(props, { emit }) {
    const searchRef = reactive(_.clone(searchModel));

    const pagination = reactive({
      current: 1,
      total: 10,
    });

    const tableSource = ref([]);
    const tableLoading = ref(false);

    async function getList() {
      tableLoading.value = true;
      const { code, data } = await awaitWrap(liveList(searchRef));
      tableLoading.value = false;
      if (code === 200) {
        tableSource.value = data.data.DataList;
        pagination.total = data.data.RecordCount;
      }
    }

    function handlePageChange(val: any) {
      pagination.current = val;
      searchRef.PageIndex = val;

      getList();
    }

    function handleDel(el: any) {
      ModalError('你确定要删除', async () => {
        const { code } = await awaitWrap(liveDel(el.Id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('删除成功');
            getList();
            resolve('');
          } else {
            reject();
          }
        });
      });
    }

    function handleInfo(el: any) {
      emit('handle-info', el.Id);
    }

    getList();
    return {
      searchRef,
      pagination,
      tableSource,
      tableLoading,
      tableModel,
      LiveEnum,
      handlePageChange,
      handleDel,
      handleInfo,
    };
  },
});
