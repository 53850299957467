
import { defineComponent, ref, reactive } from 'vue';
import { bannerDel, bannerList } from '@/api/site';
import YImage from '@/components/modules/image/index.vue';
import AddBanner from './components/AddBanner.vue';
import AddLive from './components/addLive.vue';
import LiveBox from './pc/live.vue';

import { tableModel, searchModel, clientEnum } from '@/data/modules/site/banner';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
import { ModalError } from '@/util/message';
import _ from 'lodash';
export default defineComponent({
  components: { YImage, AddBanner, AddLive, LiveBox },
  setup() {
    const searchRef = reactive(_.clone(searchModel));

    const pagination = reactive({
      current: 1,
      total: 10,
    });

    const tableSource = ref([]);
    const tableLoading = ref(false);

    async function getList() {
      tableLoading.value = true;
      const { code, data } = await awaitWrap(bannerList(searchRef));
      tableLoading.value = false;
      if (code === 200) {
        tableSource.value = data.data.DataList;
        pagination.total = data.data.RecordCount;
      }
    }

    function handlePageChange(val: any) {
      pagination.current = val;
      searchRef.PageIndex = val;
      addId.value = 0;
      getList();
    }

    function handleDel(el: any) {
      ModalError('你确定要删除', async () => {
        const { code } = await awaitWrap(bannerDel(el.Id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('删除成功');
            getList();
            resolve('');
          } else {
            reject();
          }
        });
      });
    }

    const addVisible = ref(false);
    const addId = ref(0);
    function handleInfo(el: any) {
      addVisible.value = true;
      addId.value = el.Id;
    }

    function handleAdd() {
      addVisible.value = true;
      addId.value = 0;
    }

    const liveVisible = ref(false);
    const liveId = ref(0);
    const liveBox = ref();
    function handleAddLive(id: any) {
      liveVisible.value = true;
      liveId.value = id;
    }

    function handleSaveLive() {
      liveId.value = 0;
      liveBox.value.handlePageChange(1);
    }
    getList();

    return {
      searchRef,
      pagination,
      tableSource,
      tableLoading,
      tableModel,
      clientEnum,
      addId,
      addVisible,
      handleInfo,
      handlePageChange,
      handleAdd,
      activeKey: ref('1'),
      handleDel,
      liveVisible,
      liveId,
      liveBox,
      handleAddLive,
      handleSaveLive,
    };
  },
});
