
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import upload from '@/components/modules/upload/index.vue';

import { bannerAdd, bannerDetail } from '@/api/site';
import { infoModel, clientEnum } from '@/data/modules/site/banner';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
import _ from 'lodash';

export default defineComponent({
  components: { upload },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    Id: {
      type: [String, Number],
      default: 0,
    },
  },
  emits: ['update:visible', 'handld-save'],

  setup(props: any, { emit }) {
    const show = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });

    const formRef = ref();

    const info = reactive(_.clone(infoModel));

    const roleInfo = [
      {
        Avatar: [{ required: true, message: '请输入上传头像', trigger: 'change' }],
        Banner: [{ required: true, message: '请输入上传轮播图', trigger: 'change' }],
        CardBack: [{ required: true, message: '请输入身份证正面', trigger: 'change' }],
        CardFront: [{ required: true, message: '请输入身份证反面', trigger: 'change' }],
        OrderDateEnd: [{ required: true, message: '请输入接单时间', trigger: 'change' }],
        Mobile: [{ required: true, message: '请输入电话', trigger: 'change' }],
        Birthday: [{ required: true, message: '请输入出生日期', trigger: 'change' }],
        NickName: [{ required: true, message: '请输入昵称', trigger: 'change' }],
      },
    ];

    watch(
      () => props.Id,
      (val) => {
        if (val) {
          handleDetail(val);
        } else {
          _.assignIn(info, infoModel);
        }
      }
    );

    const imgUrlComputed = computed({
      get() {
        return info.ImgUrl ? info.ImgUrl.split('|') : [];
      },
      set(val: Array<string>) {
        formRef.value.clearValidate('Avatar');
        info.ImgUrl = val.join('|');
      },
    });

    //获取详情
    async function handleDetail(ID: any) {
      const { code, data } = await awaitWrap(bannerDetail(ID));
      if (code === 200) {
        _.assign(info, data.data);
        show.value = true;
      }
    }

    async function haeldAdd(callback: () => void) {
      const validate = await awaitWrap(formRef.value.validate());
      if (validate.code === 200) {
        const ret = await awaitWrap(bannerAdd(info));
        if (ret.code === 200) {
          message.success('添加成功');
          _.assignIn(info, infoModel);
          emit('handld-save', {});
          show.value = false;
        }
      }
      callback();
    }

    return { info, show, formRef, clientEnum, imgUrlComputed, roleInfo, haeldAdd };
  },
});
