import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")
  const _component_a_table = _resolveComponent("a-table")
  const _component_table_box = _resolveComponent("table-box")

  return (_openBlock(), _createBlock(_component_table_box, {
    onPageChange: _ctx.handlePageChange,
    pagination: _ctx.pagination
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        dataSource: _ctx.tableSource,
        pagination: false,
        rowKey: "Id",
        columns: _ctx.tableModel
      }, {
        action: _withCtx(({ record }) => [
          _createVNode("a", {
            href: "javascript:;",
            onClick: ($event: any) => (_ctx.handleInfo(record))
          }, "编辑", 8, ["onClick"]),
          _createVNode(_component_a_divider, { type: "vertical" }),
          _createVNode("a", {
            href: "javascript:;",
            onClick: ($event: any) => (_ctx.handleDel(record))
          }, "删除", 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["dataSource", "columns"])
    ]),
    _: 1
  }, 8, ["onPageChange", "pagination"]))
}