import request from './axios';

export interface CompanyInfo {
	companyName: string;
	id: number;
	linkPhone: string;
	linker: string;
	simpleName: string;
}
// 添加企业
export const addCompany = (data: CompanyInfo): Promise<any> => {
  return request({
    url: '/company/info/add',
    method: 'post',
    data: data,
  });
};

//企业列表

export const companyList = (params:any): Promise<any> => {
  return request({
    url: '/company/info/list',
    method: 'get',
    params:params
  });
};


//启用
export const companyEnable = (id:any): Promise<any> => {
  return request({
    url: `/company/info/${id}/enable`,
    method: 'put',
  });
};

//禁用
export const companyDisabled = (id:any): Promise<any> => {
  return request({
    url: `/company/info/${id}/disabled`,
    method: 'put',
  });
};


//添加企业路由
export const companyRouteAdd = (data:any): Promise<any> => {
  return request({
    url: `/company/info/${data.Id}/route/add`,
    method: 'post',
    data:{RouteIdList:data.RouteIdList}
  });
};

//企业路由列表
export const companyRouteList = (id:any): Promise<any> => {
  return request({
    url: `/company/info/${id}/route/list`,
    method: 'get',
  });
};


//路由添加
export const routerAdd = (data:any): Promise<any> => {
  return request({
    url: `/manage/route/add`,
    method: 'post',
    data:data
  });
};

//路由列表
export const routerList = (): Promise<any> => {
  return request({
    url: `/manage/route/list`,
    method: 'get',
  });
};
//路由菜单
export const routerMenuList = (): Promise<any> => {
  return request({
    url: `/manage/index/menu`,
    method: 'get',
  });
};

//路由删除
export const routerDel = (id:any): Promise<any> => {
  return request({
    url: `/manage/route/${id}/del`,
    method: 'DELETE',
  });
};

//路由检查
export const routerCheck = (RouteUrl:any): Promise<any> => {
  return request({
    url: `/manage/route/auth/check`,
    method: 'get',
    params:{RouteUrl}
  });
};





//员工列表
export const userList = (params:any): Promise<any> => {
  return request({
    url: `/company/user/list`,
    method: 'get',
    params:params
  });
};

//添加员工
export const userAdd = (data:any): Promise<any> => {
  return request({
    url: `/company/user/add`,
    method: 'post',
    data:data
  });
};

//删除员工
export const userDel = (id:any): Promise<any> => {
  return request({
    url: `/company/user/${id}/del`,
    method: 'delete',
  });
};

//员工密码重置
export const userReset = (id:any): Promise<any> => {
  return request({
    url: `/company/user/${id}/reset`,
    method: 'put',
  });
};


//获取所有员工
export const userAllList = (): Promise<any> => {
  return request({
    url: `/company/user/all`,
    method: 'get',
  });
};



//角色列表
export const roleList = (params:any): Promise<any> => {
  return request({
    url: `/company/role/list`,
    method: 'get',
    params:params
  });
};

//添加角色
export const roleAdd = (data:any): Promise<any> => {
  return request({
    url: `/company/role/add`,
    method: 'post',
    data:data
  });
};

//删除角色
export const roleDel = (id:any): Promise<any> => {
  return request({
    url: `/company/role/${id}/del`,
    method: 'delete',
  });
};

//角色下添加员工
export const roleAdduser = (data:any): Promise<any> => {
  return request({
    url: `/company/role/user/add`,
    method: 'post',
    data:data
  });
};

//角色下员工
export const roleUserList = (params:any): Promise<any> => {
  return request({
    url: `/company/role/${params.Id}/user/list`,
    method: 'get',
  });
};

//角色下添加路由
export const roleAddroute = (data:any): Promise<any> => {
  return request({
    url: `/company/role/route/add`,
    method: 'post',
    data:data
  });
};

//角色下路由
export const roleRouteList = (params:any): Promise<any> => {
  return request({
    url: `/company/role/${params.Id}/route/list`,
    method: 'get',
  });
};


//区域添加
export const areaAdd = (data:any): Promise<any> => {
  return request({
    url: `/company/area/add`,
    method: 'post',
    data:data
  });
};

//列表
export const areaList = (params:any): Promise<any> => {
  return request({
    url: `/company/area/list`,
    method: 'get',
    params:params
  });
};

//区域删除
export const areaDel = (id:any): Promise<any> => {
  return request({
    url: `/company/area/${id}/del`,
    method: 'delete',
  });
};

//区域下用户列表
export const areaUserList = (params:any): Promise<any> => {

  return request({
    url: `/company/area/${params.Id}/user/list`,
    method: 'get'
  });
};
//区域用户添加
export const areauserAdd = (data:any): Promise<any> => {
  return request({
    url: `/company/area/user/add`,
    method: 'post',
    data:data
  });
};




